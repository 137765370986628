.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
}

.modalOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
