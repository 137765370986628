@tailwind base;
@tailwind components;

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(./Gotham.woff2) format('woff2');
  src: local('Gotham'), url(./Gotham.otf) format('otf');
}

@tailwind utilities;

@layer components {
  .form-row {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .without-ring {
    @apply focus:ring-0 focus:ring-offset-0;
  }
}

body {
  margin: 0;
  font-family: 'Gotham', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
